import { Component } from 'react';
import FJSvg from '../FJSvg/FJSvg';
import FJLocalStore from '../../src/store/FJLocalStore';
import classNames from 'classnames';
import Style from './FJVideoBrick.module.less';
import FJUtil from '../../src/util/FJUtil';
import FJConfig from '../../src/config/FJConfig';
import FJSrcUtil from '../../src/util/FJUtil';

class FJVideoBrick extends Component {
  _drawTool = () => {
    let { videoToolList, addEalog, useAnimation = false, itemNameClass = '' } = this.props;
    return videoToolList.toolList.map((item, index) => {
      let URL = '';
      let hasLocalization = item.hasLocalization;
      if (hasLocalization) {
        URL = FJUtil.getCurrentUrl(item.hasSpecialLang) + '/' + item.url;
      } else {
        URL = FJConfig.serverPath + '/' + item.url;
      }
      let click = () => {
        if (addEalog) {
          const eventType = addEalog.addEalog || 'home click';
          FJUtil.ealog(eventType, {
            'click name': `(${item.ealogName}) tool button`,
          });
        }
      };
      if (item.is_end === '1') {
        return (
          <a
            className={classNames(Style.toolItem, useAnimation ? Style.itemAnimationStart : '')}
            key={index}
            href={URL}
            onClick={click}
          >
            <div className={classNames(Style.itemEnd)}>
              <p
                className={Style.itemEndTitle}
                dangerouslySetInnerHTML={{
                  __html: FJLocalStore._(item.name),
                }}
              />
              <div className={Style.itemIcon}>
                <FJSvg
                  src={item.icon}
                  alt={FJLocalStore._(item.name)}
                  loading={'lazy'}
                  width={'16px'}
                  height={'16px'}
                  element={'div'}
                />
              </div>
            </div>
          </a>
        );
      } else {
        return (
          <a
            className={classNames(Style.toolItem, useAnimation ? Style.itemAnimationStart : '')}
            key={index}
            href={URL}
            onClick={click}
          >
            <div className={Style.icon_box}>
              <FJSvg
                src={item.icon}
                alt={FJLocalStore._(item.name)}
                loading={'lazy'}
                width={'70px'}
                height={'70px'}
                element={'div'}
              />
            </div>
            <p className={classNames(Style.itemName, itemNameClass)}>{FJLocalStore._(item.name)}</p>
          </a>
        );
      }
    });
  };

  getAllLi = () => {
    const ulElement = this.ulRef;

    let liElements = [];
    if (ulElement) {
      liElements = ulElement.querySelectorAll('a');
    }

    return liElements;
  };

  // 添加观察器
  blockMoveToCenter = () => {
    const ulElement = this.ulRef;
    if (ulElement) {
      const AnimationHandler = new FJSrcUtil.AnimationHandler();
      const liElements = this.getAllLi();
      liElements.forEach((li, index) => {
        AnimationHandler.transformAnimation(
          li,
          [{ transform: 'translateY(0)', opacity: 1 }],
          {
            duration: 500,
            delay: index * 100,
            easing: 'linear',
            fill: 'forwards',
          },
          true,
        );
      });
    }
  };

  // 观察期卸载观察dom
  unobserve = () => {
    const AnimationHandler = new FJSrcUtil.AnimationHandler();
    const liElements = this.getAllLi();
    liElements.forEach(li => {
      AnimationHandler.unobserve(li);
    });
  };

  componentDidMount() {
    const { useAnimation = false } = this.props;

    if (!useAnimation) {
      return;
    }
    this.blockMoveToCenter();
  }

  componentWillUnmount() {
    this.unobserve();
  }

  render() {
    let { videoToolList, style, themes } = this.props;
    return (
      <div className={classNames(Style.videoBox, themes === 'line' ? Style.themesLine : '')} style={style}>
        <h2 className={Style.videoTitle}>{FJLocalStore._(videoToolList.title)}</h2>
        <div className={Style.contentList} ref={e => (this.ulRef = e)}>
          {this._drawTool()}
        </div>
      </div>
    );
  }
}
export default FJVideoBrick;
